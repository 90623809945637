import {
  FC,
  JSX,
  useState,
  useEffect,
  ChangeEvent,
  CSSProperties,
} from "react";
import {
  DocumentListType,
  SelectOptionType,
  AttributesTypeProps,
  RelationDocumentType,
  DocumentListResultsType,
  DocumentTableParamsType,
} from "app/types";
import { getDocsList } from "../../api/document.api";
import { ReactComponent as SearchIcon } from "../../assets/icons/CustomInput/search.svg";
import { AutoSizeType } from "rc-textarea/lib/interface";
import { getUsersListing } from "../../api/account.api";
import { SizeType } from "antd/es/config-provider/SizeContext";
import { Input, Select } from "antd";
import { useSelector } from "react-redux";
import { AppStateType } from "../../reducers/mainReducer";
import { JWTPayload } from "jose";

export interface ISelectOption {
  value: string;
  label: string;
}

export interface ICustomInputProps {
  id: string;
  size?: SizeType;
  mode?: "multiple" | "tags" | undefined;
  rows?: number;
  type?: "default" | "search" | "select" | "textField";
  label?: string;
  value?: string;
  status?: "" | "error" | "warning" | undefined;
  isEmail?: boolean;
  options?: ISelectOption[];
  variant?: "filled" | "borderless" | "outlined" | undefined;
  loading?: boolean;
  autoSize?: boolean | AutoSizeType;
  disabled?: boolean;
  isSearch?: boolean;
  showCount?: boolean;
  className?: string;
  maxLength?: number;
  allowClear?: boolean;
  inputStyle?: CSSProperties,
  selectStyle?: CSSProperties,
  searchValue?: string;
  textFieldStyle?: CSSProperties,
  isResizable?: boolean;
  placeholder?: string;
  marksPlaceholder?: string;
  defaultValue?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement|HTMLTextAreaElement>) => void;
  onSelect?: (event: string | JSX.Element) => void;
  onClick?: (e: any) => void;
  onClear?: () => void;
  suffixicon?: JSX.Element;
  selectValue?: string | JSX.Element;
  dropdownStyle?: CSSProperties | undefined;
  optionsSchema?: string;
  showSearch?: boolean;
  docsListParams?: DocumentTableParamsType;
  onSearch?: ((value: string) => void) | undefined;
  onBlur?: any;
  onSelectChange?: (
    (value: (string | JSX.Element),
    option?: (SelectOptionType | SelectOptionType[])) => void)
    | undefined;
  isDebounced?: boolean;
  isCounted?: boolean;
  maxCount?: number;
}

const { Search } = Input;
const { TextArea } = Input;

export const CustomInput: FC<ICustomInputProps> = ({
  id,
  size = "large",
  mode,
  rows,
  type,
  value,
  status,
  isEmail,
  options,
  variant = "outlined",
  loading,
  autoSize,
  disabled,
  isSearch,
  showCount,
  className,
  maxLength,
  allowClear,
  inputStyle,
  selectStyle,
  searchValue: propValue,
  textFieldStyle,
  isResizable = false,
  placeholder,
  defaultValue,
  onChange,
  onSelect,
  onClear,
  onClick,
  suffixicon,
  selectValue,
  dropdownStyle,
  optionsSchema,
  onSelectChange,
  showSearch,
  docsListParams,
  onSearch,
  onBlur,
  isDebounced = true,
  isCounted,
  maxCount,
}): JSX.Element => {
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
  const [inputValue, setInputValue] = useState<string>(propValue || "");
  const [optionsFields, setOptionsFields] = useState<SelectOptionType[]>([]);

  const decodedToken: JWTPayload | null = useSelector((state: AppStateType) => state.account?.decodedToken);

  const isMultipleMode: boolean = mode === "multiple";

  const textAreaStyle: CSSProperties = {
    ...textFieldStyle,
    resize: isResizable ? "both" : "none",
  };

  useEffect(() => {
    return () => {
      setInputValue("");
      setOptionsFields([]);
    };
  }, []);

  useEffect(() => {
    const usersListParams = {
      page_size: 100,
      attributes: decodedToken?.short_name ? `short_name:${decodedToken?.short_name}` : null
    };

    if (optionsSchema) {
      if (optionsSchema !== "allUsers") {
        getDocsList(optionsSchema, docsListParams)
        ?.then((response: any): void => {
          const responseData: DocumentListType = response?.data as DocumentListType;
          const optionsFields: RelationDocumentType[] = responseData?.results?.flatMap(({ fields }: any) => fields);

          optionFieldsMap(optionsFields);
        })
        ?.catch((error: any): void => console.error("Get options list error", error));
      } else {
        getUsersListing(usersListParams)
        ?.then((response: any): void => {
          const responseData: DocumentListType = response?.data as DocumentListType;
          const filteredUsers: DocumentListResultsType[] = responseData?.results?.filter((user) => {
            return user?.id !== decodedToken?.sub;
          });

          const newOptions: SelectOptionType[] = filteredUsers?.map(({
            id,
            email,
            attributes
          }: DocumentListResultsType) => {
            return {
              value: isEmail ? email : id,
              label: formatLabel(attributes),
            };
          });

          setOptionsFields(newOptions);
        })
        ?.catch((error: any): void => console.error("Get user list error", error));
      }
    }
  }, [optionsSchema, docsListParams?.region_name, docsListParams?.category_name]);

  useEffect(() => {
    if (value) {
      setInputValue(value);
    }
  }, [value]);

  const formatLabel = (attributes: AttributesTypeProps): string => {
    const { position, last_name, first_name, second_name } = attributes || {};

    return [
      position,
      last_name,
      first_name,
      second_name,
    ]
    .filter(Boolean)
    .join(" ");
  };

  const optionFieldsMap = (options: RelationDocumentType[]): void => {
    const newOptions: SelectOptionType[] = options?.map((option: RelationDocumentType) => {
      const optionInfo: string | undefined = optionsSchema === "category" ? option.category_name : option.region_name;

      return {
        label: optionInfo,
        value: isMultipleMode ? option?.uuid : optionInfo,
      };
    });

    setOptionsFields(newOptions);
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    const newValue: string = event.target.value;
    setInputValue(newValue);

    if (timer) {
      clearTimeout(timer);
    }

    const newTimer: NodeJS.Timeout = setTimeout((): void => {
      if (onChange) {
        onChange(event);
      }
    }, 500);

    setTimer(newTimer);
  };

  const commonInputProps = {
    id,
    size,
    disabled,
    className,
    placeholder,
    defaultValue,
    onChange: isDebounced ? handleChange : onChange,
    onClear,
    variant,
    suffixicon,
  };

  return (
    <>
      {type === "default" && (
        <Input
          {...commonInputProps}
          style={inputStyle}
          value={isDebounced ? inputValue : value}
          status={status}
          showCount={showCount}
          allowClear={allowClear}
          onClear={onClear}
          suffix={isSearch && <SearchIcon />}
          count={{
            show: isCounted,
            max: maxCount,
          }}
        />
      )}
      {type === "search" && (
        <Search
          {...commonInputProps}
          style={inputStyle}
          value={inputValue}
          status={status}
          loading={loading}
          allowClear={allowClear}
        />
      )}
      {type === "select" && (
        <Select
          id={id}
          size={size}
          mode={mode}
          style={selectStyle}
          value={!selectValue ? value : selectValue}
          status={status}
          options={options || optionsFields}
          loading={loading}
          disabled={disabled}
          className={className}
          allowClear={allowClear}
          showSearch={showSearch}
          placeholder={placeholder}
          defaultValue={defaultValue}
          onSearch={onSearch}
          filterOption={false}
          onSelect={onSelect}
          onClear={onClear}
          onChange={onSelectChange}
          onClick={onClick}
          suffixIcon={suffixicon}
          dropdownStyle={dropdownStyle}
          onBlur={onBlur}
        />
      )}
      {type === "textField" && (
        <TextArea
          {...commonInputProps}
          rows={rows}
          style={textAreaStyle}
          value={inputValue}
          status={status}
          autoSize={autoSize}
          showCount={showCount}
          maxLength={maxLength}
          allowClear={allowClear}
        />
      )}
    </>
  );
};
