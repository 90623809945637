import { ChangeEvent } from "react";
import { FormInstance } from "antd";

export const handleNumericInputChange = (
  e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  form: FormInstance,
  fieldName: string,
  maxLength?: number,
  valueName = "Значение",
): void => {
  const { value } = e.target;
  const numericValue: string = value.replace(/\D/g, "");
  const trimmedValue: string = maxLength ? numericValue?.slice(0, maxLength) : numericValue;

  form.setFieldsValue({ [fieldName]: trimmedValue });

  const errors: string[] = maxLength && trimmedValue.length !== maxLength
    ? [`${valueName} должно быть ${maxLength} символов`]
    : [];

  form.setFields([
    {
      name: [fieldName],
      errors,
    },
  ]);
};
