import React, { FC, JSX } from "react";
import { ReactComponent as StarIcon } from "../../assets/icons/CustomTable/star.svg";
import Tag from "../Tag/Tag";
import css from "./ListItem.module.css";
import { MarkType, renderMarkDefinition } from "./utils/renderMarkDefinition";

interface IListItemProps {
  value?: number | string | string[];
  label?: string;
  isTag?: boolean,
  isMark?: boolean,
  className?: string;
}

type renderListItemType = JSX.Element[] | JSX.Element | number | string | string[];

const renderTags = (items: string[]): JSX.Element[] => (
  items.map((item: string): JSX.Element => (
    <Tag
      key={item}
      tag={item}
      isTableTag
      className="mr-2"
    />
  ))
);

const renderMarks = (mark: MarkType): JSX.Element => (
  <span className="flex items-center">
    <StarIcon className="mr-2" />
    {mark}
    {renderMarkDefinition(mark)}
  </span>
);

const ListItem: FC<IListItemProps> = ({
  value,
  label,
  isTag = false,
  isMark = false,
  className = "",
}): null | JSX.Element => {
  if (!value) return null;

  const renderValue = (): renderListItemType => {
    if (isTag && Array.isArray(value)) return renderTags(value);
    if (isMark && typeof value === "number") return renderMarks(value as MarkType);

    return value || "-";
  };

  return (
    <li className="flex items-center mb-2">
      <div className={`${className} ${css.listItemLabel}`.trim()}>{label}</div>
      <div className="flex flex-wrap">{renderValue()}</div>
    </li>
  );
};

export default ListItem;
