// Пункты меню для кнопки настроек
export const settingsItems = [
  {
    key: "1",
    label: "Добавить новую оценку",
  },
  {
    key: "2",
    label: "Получить ссылку для добавления оценки",
  },
];

// Наименование столбцов для отрисовки тегов
export const tagKeys: string[] = ["supplier_categories", "supplier_categories.category_name"];
// Наименование столбцов для отрисовки ссылок
export const linkKeys: string[] = ["inn", "company_name", "marks_count"];
// Наименование столбцов для отрисовки рейтинга
export const ratingKeys: string[] = [
  "mark",
  "average_mark",
  "vtb_average_mark",
  "central_bank_average_mark",
];

// Указываем ширину столбцов
export const columnWidth: Record<string, string>[] = [
  { "inn": "20%" },
  { "mark": "10%" },
  { "marks_count": "10%" },
  { "company_name": "20%" },
  { "vtb_average_mark": "10%" },
  { "supplier_categories": "20%" },
  { "central_bank_average_mark": "10%" },
  { "supplier_categories.category_name": "20%" },
];

export const sortableColumns: Record<string, boolean> = {
  inn: false,
  marks_count: true,
  average_mark: true,
  company_name: true,
  vtb_average_mark: true,
  supplier_categories: true,
  central_bank_average_mark: true,
  ["supplier_categories.category_name"]: false,
};
