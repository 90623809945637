import {
  JSX,
  useState,
  useEffect,
} from "react";
import { DocumentListResultsType } from "app/types";
import { AppDispatch } from "./store/store";
import { useDispatch, useSelector } from "react-redux";
import { AppStateType } from "./reducers/mainReducer";
import { NavigateFunction, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { getSchemasList } from "./actions/documents.actions";
import { decodeToken } from "./actions/account.actions";
import ConfirmNewPasswordForm from "./components/LoginForm/ConfirmPasswordForm";
import ResetPasswordForm from "./components/LoginForm/ResetPasswordForm";
import LoginForm from "./components/LoginForm/LoginForm";
import NavBar from "./components/NavBar/NavBar";
import SuppliersList from "./pages/SuppliersList/SuppliersList";
import SupplierCard from "./pages/SupplierCard/SupplierCard";
import MarkCard from "./pages/MarkCard/MarkCard";
import MarkForm from "./pages/MarkForm/MarkForm";
import StubPage from "./pages/StubPage/StubPage";
import CreateSupplier from "./pages/CreateSupplier/CreateSupplier";
import { Content } from "antd/es/layout/layout";
import { Affix, Layout } from "antd";
import Profile from "./components/Profile/Profile";

const emptyPathName: number = 1;

const isMenuCollapsed: string | null = window?.localStorage.getItem("isMenuCollapsed");

const App = (): JSX.Element => {
  const [isNavBarHidden, setNavBarHidden] = useState<boolean>(false);
  const [collapsed, setCollapsed] = useState<boolean>(!isMenuCollapsed);

  const navigate: NavigateFunction = useNavigate();
  const params = useLocation();

  const dispatch = useDispatch<AppDispatch>();

  const authToken: string | null = useSelector((state: AppStateType) => state.account?.authToken);
  const schemasList: DocumentListResultsType[] = useSelector((state: AppStateType) => state.document.schemasList);

  const isStubPage: boolean = params.pathname?.length === emptyPathName;

  useEffect((): void => {
    if (authToken) {
      dispatch(decodeToken(authToken));
    }
  }, [authToken]);

  useEffect(() => {
    if (!authToken && !isStubPage) {
      navigate("/login");
    }
  }, [navigate, authToken]);

  useEffect((): void => {
    if (!schemasList?.length) {
      dispatch(getSchemasList());
    }
  }, []);

  return (
    <Layout hasSider={!!authToken}>
      {!isNavBarHidden && (
        <Affix>
          {!!authToken &&
            <NavBar
              setNavBarHidden={setNavBarHidden}
              collapsed={collapsed}
              setCollapsed={setCollapsed}
            />}
        </Affix>
      )}
      <Content style={{ transition: "width 0.3s ease-out" }}>
        <Routes>
          <Route path="/" element={<StubPage />} />
          <Route path="/login" element={<LoginForm setNavBarHidden={setNavBarHidden} />}/>
          <Route path="/reset-password" element={<ResetPasswordForm />}/>
          <Route path="/confirm-password" element={<ConfirmNewPasswordForm />} />
          <Route path="/profile" element={<Profile />} />
          {!!authToken && (
            <>
              <Route path="/suppliers/*" element={<SuppliersList />} />
              <Route path="/supplier/:id/*" element={<SupplierCard />} />
              <Route path="/supplier/:id/marks/:id" element={<MarkCard />} />
              <Route path="/mark-form/*" element={<MarkForm setNavBarHidden={setNavBarHidden} />} />
              <Route path="/create-supplier/" element={<CreateSupplier />} />
            </>
          )}
        </Routes>
      </Content>
    </Layout>
  );
};

export default App;
