import React, {
  ChangeEvent,
  FC,
  JSX,
  useEffect,
  useState
} from "react";
import {
  Form,
  Input,
  Modal,
} from "antd";
import css from "./ProfilePersonalInformation.module.css";
import { ButtonCustom } from "../../ui-kit/ButtonCustom/ButtonCustom";
import { UserInfoUpdateDataType } from "app/types";
import { useDispatch, useSelector } from "react-redux";
import { AppStateType } from "../../../reducers/mainReducer";
import { AppDispatch } from "../../../store/store";
import { decodeToken, fetchNewToken, updateUserData } from "../../../actions/account.actions";
import {
  handlePhoneNumberChange,
  normalFormatPhoneNumber,
  phoneValidator,
} from "../../../utils/phoneNumberMask";
import * as jose from "jose";
import TagCustom from "../../ui-kit/TagCustom/TagCustom";
import ListItem from "../../ui-kit/RenderListItem/ListItem";

interface IProfilePersonalInformationProps {
}

interface IJWTPayloadProps extends jose.JWTPayload {
  last_name?: string;
  first_name?: string;
  second_name?: string;
  phone?: string;
  email?: string;
  resource_access?: {
    bc: {
      roles: string[];
    }
  }
}

const ProfilePersonalInformation: FC<IProfilePersonalInformationProps> = (): JSX.Element => {
  const [isEditModalOpen, setEditModalOpen] = useState<boolean>(false);
  const [isSaveBtnDisabled, setSaveBtnDisabled] = useState<boolean>(false);

  const dispatch = useDispatch<AppDispatch>();

  const {
    authToken,
    userData,
  } = useSelector((state: AppStateType) => state.account);

  const decodedToken: IJWTPayloadProps | null = useSelector((state: AppStateType) => state.account.decodedToken);
  const rolesListing: string[] | undefined = decodedToken?.resource_access?.bc?.roles;

  const [firstName, setFirstName] = useState<string>(decodedToken?.first_name as string);
  const [secondName, setSecondName] = useState<string>(decodedToken?.second_name as string);
  const [lastName, setLastName] = useState<string>(decodedToken?.last_name as string);
  const [phoneNumber, setPhoneNumber] = useState<string>(handlePhoneNumberChange(decodedToken?.phone as string));

  useEffect((): void => {
    if (authToken) {
      dispatch(decodeToken(authToken));
    }
  }, [authToken]);

  //Логика редактирования данных
  const [userDataAttributes, setUserDataAttributes] = useState<UserInfoUpdateDataType>({
    phone: decodedToken?.phone as string,
    first_name: decodedToken?.first_name as string,
    last_name: decodedToken?.last_name as string,
    second_name: decodedToken?.second_name as string,
  });

  const [personalInformationForm] = Form.useForm();

  const values = Form.useWatch([], personalInformationForm);

  useEffect((): void => {
    setUserDataAttributes({
      phone: phoneNumber,
      first_name: firstName,
      last_name: lastName,
      second_name: secondName,
    });

    personalInformationForm?.setFieldValue("lastName", lastName);
    personalInformationForm?.setFieldValue("firstName", firstName);
    personalInformationForm?.setFieldValue("secondName", secondName);
    personalInformationForm?.setFieldValue("phone", phoneNumber);
  }, [personalInformationForm, decodedToken, phoneNumber]);

  useEffect(() => {
    personalInformationForm
    .validateFields({ validateOnly: true })
    .then(() => setSaveBtnDisabled(true))
    .catch(() => setSaveBtnDisabled(false));
  }, [personalInformationForm, values, decodedToken]);

  const saveNewUserData = async (): Promise<any> => {
    const refreshToken: string | null = window?.localStorage?.getItem("refreshToken");
    await dispatch(updateUserData(userData?.sub, userDataAttributes));

    setEditModalOpen(false);

    if (refreshToken) {
      const token: string = refreshToken.replace(/^"(.*)"$/, "$1");
      dispatch(fetchNewToken(token));
    }
  };

  const handlePhoneNumber = (e: ChangeEvent<HTMLInputElement>): void => {
    const value: string = e.target.value;
    const formattedNumber: string = handlePhoneNumberChange(value);
    setPhoneNumber(formattedNumber);
    setUserDataAttributes(
      {...userDataAttributes, phone: normalFormatPhoneNumber(formattedNumber)}
    );
  };

  const closeModal = (): void => {
    setEditModalOpen(false);
    personalInformationForm.resetFields();
    setFirstName(decodedToken?.first_name as string);
    setSecondName(decodedToken?.second_name as string);
    setLastName(decodedToken?.last_name as string);
  };

  const tagText = (roleValue: string): string => {
    switch (roleValue) {
      case "admin-role":
        return "Администратор";
      case "quality-control-role":
        return "Сотрудник контроля качества";
      case "tech-support-role":
        return "Сотрудник технической поддержки";
      case "accountant-role":
        return "Бухгалтер";
      case "assistant-role":
        return "Ассистент";
      case "contact-center-role":
        return "Сотрудник Контакт-центра";
      default:
        return "";
    }
  };

  return (
    <>
      <div className="mb-4">
        <div className="p-5 mb-3 bg-white border-bottom-gray rounded-lg">
          <h1 className={css.userLabel}>
            Роли и права
          </h1>
          {rolesListing?.map((role: string, index: number) => (
            <TagCustom key={index} color="customDraft" text={tagText(role)} />
          ))}
        </div>
        <div className="p-5 mb-3 bg-white border-bottom-gray rounded-lg">
          <div className="flex justify-between items-center mb-5">
            <h1 className="header-text">
              Персональные данные
            </h1>
            {/*TODO скрываем кнопку пока не будем поддерживать редактирование синхронное кейклоак */}
            {/*и профиля пользователя*/}
            {/*<ButtonCustom type="link" text="Редактировать" onClick={openEditUserModal}/>*/}
          </div>
          <ul>
            <ListItem value={decodedToken?.last_name ?? "-"} label="Фамилия" />
            <ListItem value={decodedToken?.first_name ?? "-"} label="Имя" />
            <ListItem value={decodedToken?.second_name ?? "-"} label="Отчество" />
            <ListItem
              value={decodedToken?.phone
                ? handlePhoneNumberChange(decodedToken?.phone ?? "-")
                : "-"}
              label="Телефон"
            />
            <ListItem value={decodedToken?.email ?? "-"} label="Электронная почта" />
          </ul>
        </div>
        {isEditModalOpen && (
          <Modal
            title="Персональные данные"
            centered
            open={isEditModalOpen}
            onOk={() => setEditModalOpen(false)}
            onCancel={closeModal}
            footer={null}
          >
            <span className="block text-gray-500 mb-4 mt-3">
              Внесите необходимые изменения в ваши персональные данные
            </span>
            <Form
              form={personalInformationForm}
              name="personalInformationForm"
              layout="vertical"
              onFinish={saveNewUserData}
            >
              <Form.Item
                id="lastName"
                name="lastName"
                className="mb-4"
                label="Фамилия"
                rules={[
                  {
                    required: true,
                    message: "Пожалуйста, введите фамилию"
                  }
                ]}
                initialValue={decodedToken?.last_name}
              >
                <Input
                  size="large"
                  placeholder="Введите фамилию"
                  value={lastName}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                    setUserDataAttributes(
                      { ...userDataAttributes, last_name: e.target.value }
                    );
                    setLastName(e.target.value);
                  }}
                />
              </Form.Item>
              <Form.Item
                id="firstName"
                name="firstName"
                className="mb-4"
                label="Имя"
                rules={[
                  {
                    required: true,
                    message: "Пожалуйста, введите имя"
                  }
                ]}
                initialValue={decodedToken?.first_name}
              >
                <Input
                  size="large"
                  placeholder="Введите имя"
                  value={firstName}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                    setUserDataAttributes(
                      { ...userDataAttributes, first_name: e.target.value }
                    );
                    setFirstName(e.target.value);
                  }}
                />
              </Form.Item>
              <Form.Item
                id="secondName"
                name="secondName"
                className="mb-4"
                label="Отчество"
                rules={[
                  {
                    required: true,
                    message: "Пожалуйста, введите отчество"
                  }
                ]}
                initialValue={decodedToken?.second_name}
              >
                <Input
                  size="large"
                  value={secondName}
                  placeholder="Введите отчество"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                    setUserDataAttributes(
                      { ...userDataAttributes, second_name: e.target.value }
                    );
                    setSecondName(e.target.value);
                  }}
                />
              </Form.Item>
              <Form.Item
                id="phone"
                name="phone"
                className="mb-4"
                label="Номер телефона"
                initialValue={handlePhoneNumberChange(decodedToken?.phone as string)}
                rules={[
                  {
                    min: 16,
                    message: "Пожалуйста, введите номер полностью"
                  },
                  {
                    validator: phoneValidator,
                  },
                ]}
              >
                <Input
                  id="phone"
                  size="large"
                  placeholder="+7 000 0000000"
                  maxLength={16}
                  value={phoneNumber}
                  onChange={handlePhoneNumber}
                />
              </Form.Item>
              <div className="text-right mt-5">
                <ButtonCustom
                  size="large"
                  className="mr-5"
                  type="default"
                  text="Отменить"
                  onClick={closeModal}
                />
                <ButtonCustom
                  size="large"
                  type="primary"
                  text="Сохранить"
                  htmlType="submit"
                  disabled={!isSaveBtnDisabled}
                />
              </div>
            </Form>
          </Modal>
        )}
      </div>
      {/*TODO временно скрываем таблицу*/}
      {/*{techSupportRole && (*/}
      {/*  <div className="p-5 mb-3 bg-white border-bottom-gray rounded-lg">*/}
      {/*    <h1 className="header-text mb-5">*/}
      {/*      Параметры назначений*/}
      {/*    </h1>*/}
      {/*    <Table*/}
      {/*      className={css.table}*/}
      {/*      columns={columns}*/}
      {/*      dataSource={data}*/}
      {/*      pagination={false}*/}
      {/*      locale={{emptyText: emptyDescription()}}*/}
      {/*    />*/}
      {/*  </div>*/}
      {/*)}*/}
    </>
  );
};

export default ProfilePersonalInformation;
