  import {
  FC,
  JSX,
  Dispatch,
  useState,
  useEffect,
  SetStateAction,
} from "react";
import { getDocsList, getDocument } from "../../api/document.api";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../store/store";
import { DocumentListResultsType, DocumentTableParamsType } from "app/types";
import { getDocumentFailure } from "../../actions/documents.actions";
import { NavigateFunction, useLocation, useNavigate } from "react-router-dom";
import MarkFormHeader from "./MarkFormHeader/MarkFormHeader";
import CustomLoading from "../../components/CustomLoading/CustomLoading";
import { CustomInput } from "../../components/CustomInput/CustomInput";
import { CustomButton } from "../../components/CustomButton/CustomButton";
import RenderLabel from "./utils/RatingFormItems";
import RatingFormItems from "./utils/RenderFormLabel";
import { Form, message, Tooltip } from "antd";
import { supplierMarkCreate } from "../../api/supplier.api";
import { validateInn } from "../CreateSupplier/utils/validateInn";
import { validateActivityRegion } from "../CreateSupplier/utils/validateActivityRegion";

interface IMarkFormProps {
  setNavBarHidden: Dispatch<SetStateAction<boolean>>;
}

enum InnLength {
  Max = 12,
  Min = 10,
}

const isMarkForm: boolean = true;

const MarkForm: FC<IMarkFormProps> = ({ setNavBarHidden }): JSX.Element => {
  const [isFormLoading, setIsFormLoading] = useState<boolean>(false);
  const [isSubmittable, setSubmittable] = useState<boolean>(false);
  const [markFormData, setMarkFormData] = useState<DocumentListResultsType | null>(null);
  const [contractorMarkCriteria, setContractorMarkCriteria] = useState<DocumentListResultsType[]>([]);
  const [isSupplierHasBeenCreated, setSupplierHasBeenCreated] = useState<boolean | null>(null);
  const [searchCategory, setSearchCategory] = useState<string>("");
  const [searchRegion, setSearchRegion] = useState<string>("");

  const [form] = Form.useForm();
  const values = Form.useWatch([], form);
  const location: string = useLocation()?.pathname;
  const navigate: NavigateFunction = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const markUuid: string = location?.split("/")?.[2];
  const isFormOpenFromCard: boolean = location?.split("/")?.includes("card");

  const regionParams = {
    ...(searchRegion ? { region_name: searchRegion } : {}),
    page_size: 100,
    order_by: "region_name",
  };

  const categoryParams = {
    ...(searchCategory ? { category_name: searchCategory } : {}),
    page_size: 100,
    order_by: "category_name",
  };

  useEffect(() => {
    const isValuePresent = (value: any) => {
      if (Array.isArray(value)) {
        return value.length > 0;
      }

      return Boolean(value);
    };

    const hasValues: boolean = form.getFieldsValue && Object.values(form.getFieldsValue()).every(isValuePresent);

    setSubmittable(hasValues);
  }, [form, values]);

  useEffect(() => {
    const supplierInn: string = values?.supplier_inn;
    const markInn: string = markFormData?.inn;

    if (supplierInn && markInn && supplierInn !== markInn && isSupplierHasBeenCreated) {
      form.setFieldsValue({
        supplier_categories: [],
        activity_region: [],
        company_name: null,
      });
    }
  }, [values, markFormData, isSupplierHasBeenCreated]);

  const isCorrectInnLength: boolean = values?.supplier_inn?.length >= InnLength?.Min
    && values?.supplier_inn?.length <= InnLength?.Max;

  useEffect(() => {
    if (isCorrectInnLength) {
      const supplierListParams: DocumentTableParamsType = {
        search: values?.supplier_inn,
      };

      getDocsList("supplier-counteragent", supplierListParams)
      ?.then((response: any): void => {
        setMarkFormData(response?.data?.results?.[0]?.fields);
        setSupplierHasBeenCreated(!!response?.data?.results?.length);
        form.setFieldValue("company_name", response?.data?.results?.[0]?.fields?.short_name);
      })
      ?.catch((error: any): void => {
        setSupplierHasBeenCreated(null);
        console.error("Get suppliers list error", error);
      });
    }
  }, [isCorrectInnLength, values?.supplier_inn]);

  useEffect(() => {
    if (!isSupplierHasBeenCreated && isSupplierHasBeenCreated !== null) {
      form.validateFields(["supplier_inn"]);
    } else {
      form.setFields([
        {
          name: "supplier_inn",
          errors: [],
        },
      ]);
    }
  }, [isSupplierHasBeenCreated, form]);

  useEffect(() => {
    if (markUuid) {
      fetchMarkInfo();
      setIsFormLoading(true);
    }

    setNavBarHidden(true);

    return () => {
      setNavBarHidden(false);
      dispatch(getDocumentFailure());
    };
  }, []);

  useEffect(() => {
    getDocsList("contractor-criteria")?.then((response: any): void => {
      const markCriteriaFields: DocumentListResultsType[] = response?.data?.results
        ?.flatMap(({ fields }: DocumentListResultsType): DocumentListResultsType => fields);

      setContractorMarkCriteria(markCriteriaFields);
    })
    ?.catch((error: any): void => console.error("Get contractor criteria error", error));
  }, []);

  const formItemUuids = (key: string): string[] => markFormData?.[key]?.map(({ uuid }: any) => uuid);

  const fetchMarkInfo = (): void => {
    getDocument("supplier-counteragent", markUuid)
      ?.then((response) => setMarkFormData(response?.data?.fields))
      ?.catch((err) => console.error("Get marks info error", err))
      ?.finally(() => setIsFormLoading(false));
  };

  const closeForm = (): void => {
    navigate(isFormOpenFromCard ? `/supplier/${markUuid}` : "/suppliers");
    form.resetFields();
  };

  const onFinish = (values: any): void => {
    setIsFormLoading(true);

    const newValues = {
      supplier_inn: values.supplier_inn,
      company_name: values.company_name,
      supplier_categories: [...values.supplier_categories],
      activity_region: [...values.activity_region],
      comment: values.comment,
      mark_criteria: Object.entries(values)
        .filter(([key]) => key.startsWith("mark_criteria_name_"))
        .map(([key, value]) => ({
          mark_criteria_points: value,
          mark_criteria_uuid: key.replace("mark_criteria_name_", "")
        }))
    };

    supplierMarkCreate(newValues)
      ?.then(() => {
        //Ставим задержку в полсекунды, чтоб бэк успел обновить данные
        setTimeout(() => {
          closeForm();
          message.success("Оценка успешно добавлена");
        }, 2000);
      })
      ?.catch((error: any): void => {
        message.error("Ошибка добавления оценки");
        console.error("Create supplier mark error", error);
      });
  };

  const onChange = (formItem: string, values: string[]): void => {
    form.setFieldValue(formItem, values);
  };

  const onFormItemClear = (formItem: string): void => {
    form.setFieldValue(formItem, null);
  };

  const onFormClear = (): void => {
    // Костыль, только в такой комбинации зачищается поле с наименованием компании
    form.resetFields();
    form.resetFields([null]);
    setMarkFormData(null);
    setSupplierHasBeenCreated(null);
  };

  return (
    <div className="flex justify-center p-6 mx-auto bg-white h-[100vh]">
      {isFormLoading ? (
        <CustomLoading className="flex items-center" />
      ) : (
        <div className="w-3/5">
          <MarkFormHeader closeForm={closeForm} />
          <Form form={form} name="markForm" layout="vertical" onFinish={onFinish}>
            <Form.Item
              name="supplier_inn"
              label={<RenderLabel label="ИНН поставщика" />}
              className="mb-5"
              initialValue={markFormData?.inn}
              rules={[
                {
                  required: true,
                  message: "Пожалуйста, введите ИНН"
                },
                {
                  min: 10,
                  message: "ИНН не может быть меньше 10 символов"
                },
                {
                  max: 12,
                  message: "ИНН не может быть больше 12 символов"
                },
                {
                  validator: validateInn(isSupplierHasBeenCreated, false, isMarkForm)
                }
              ]}
            >
              <CustomInput
                id="inn"
                type="default"
                allowClear
                placeholder="Введите ИНН"
                onClear={onFormClear}
              />
            </Form.Item>
            <Form.Item
              name="company_name"
              label={<RenderLabel label="Наименование поставщика" />}
              className="mb-5"
              required
            >
              <CustomInput
                id="company_name"
                type="default"
                allowClear
                placeholder="Введите наименование поставщика"
                onClear={() => onFormItemClear("company_name")}
              />
            </Form.Item>
            <Form.Item
              name="supplier_categories"
              label={<RenderLabel label="Категории" />}
              className="mb-5"
              initialValue={formItemUuids("supplier_categories")}
              validateTrigger={["onSelectChange"]}
              required
              rules={[{ validator: validateActivityRegion(values?.supplier_categories) }]}
            >
              <CustomInput
                id="category"
                type="select"
                mode="multiple"
                allowClear
                placeholder="Выберите категорию"
                optionsSchema="category"
                onSearch={(value: string) => setSearchCategory(value)}
                onSelectChange={(values: any) => onChange("supplier_categories", values)}
                onClear={() => {
                  onFormItemClear("supplier_categories");
                  setSubmittable(false);
                }}
                onBlur={() => setSearchCategory("")}
                docsListParams={categoryParams}
              />
            </Form.Item>
            <Form.Item
              name="activity_region"
              label={<RenderLabel label="Регион деятельности" />}
              className="mb-5"
              initialValue={formItemUuids("activity_region")}
              validateTrigger={["onSelectChange"]}
              required
              rules={[{ validator: validateActivityRegion(values?.activity_region, isMarkForm) }]}
            >
              <CustomInput
                id="region"
                type="select"
                mode="multiple"
                allowClear
                placeholder="Выберите регионы"
                optionsSchema="region"
                onSearch={(value: string) => setSearchRegion(value)}
                onSelectChange={(values: any) => onChange("activity_region", values)}
                onClear={() => {
                  onFormItemClear("activity_region");
                  setSubmittable(false);
                }}
                onBlur={() => setSearchRegion("")}
                docsListParams={regionParams}
              />
            </Form.Item>
            <span className="inline-block mb-5 text-[#67768C]">
              {"Пожалуйста, оцените каждый критерий по шкале от 1 до 5, где 1 " +
                "— это «совсем не удовлетворен», а 5 — «полностью удовлетворен»"}
            </span>
            <RatingFormItems contractorMarkCriteria={contractorMarkCriteria} />
            <Form.Item
              name="comment"
              label={<RenderLabel label="Комментарий" />}
              className="mb-8"
              required
              rules={[
                {
                  required: true,
                  message: "Пожалуйста, введите комментарий"
                }
              ]}
            >
              <CustomInput
                id="comment"
                type="textField"
                placeholder="Введите комментарий"
                isResizable
                allowClear
                onClear={() => onFormItemClear("comment")}
              />
            </Form.Item>
            <Form.Item>
              <div className="text-right">
                <CustomButton className="mr-2" type="default" size="large" text="Отменить" ghost onClick={closeForm} />
                <Tooltip
                  title={!isSupplierHasBeenCreated && isSupplierHasBeenCreated !== null && "Поставщик не найден"}
                >
                  <>
                    <CustomButton
                      type="primary"
                      size="large"
                      text="Подтвердить"
                      htmlType="submit"
                      disabled={!isSubmittable || !isSupplierHasBeenCreated || isFormLoading}
                    />
                  </>
                </Tooltip>
              </div>
            </Form.Item>
          </Form>
        </div>
      )}
    </div>
  );
};

export default MarkForm;
