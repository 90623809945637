export type MarkType = 1 | 2 | 3 | 4 | 5;

export const renderMarkDefinition = (mark: MarkType): string => {
  const markDefinitions: Record<MarkType, string> = {
    5: " — Отлично",
    4: " — Удовлетворен",
    3: " — Скорее удовлетворен",
    2: " — Скорее неудовлетворен",
    1: " — Неудовлетворен"
  };

  return markDefinitions[mark] || "";
};
