import {
  FC,
  JSX,
  useState,
  useEffect,
} from "react";
import {
  MarkType,
  DocumentListType,
  DocumentTableParamsType,
  RelationDocumentType,
} from "app/types";
import { columnWidth, ratingKeys, sortableColumns, subheadKeys } from "./utils/tableData";
import CustomTable from "../../components/CustomTable/CustomTable";
import { CustomPagination } from "../../components/CustomPagination/CustomPagination";
import SuppliersTableFilters from "../SuppliersList/SuppliersTable/SuppliersTableFilters/SuppliersTableFilters";
import { getMarkInfo } from "../../api/marks.api";
import { formatAuthorName } from "./utils/formatAuthorName";
import dayjs from "dayjs";

interface IMarksListProps {
  supplierUuid?: string;
  supplierRegions?: RelationDocumentType[];
  supplierCategories?: RelationDocumentType[];
}

interface ProcessedMark {
  uuid: string;
  short_name: string;
  created_at: string;
  [key: string]: string | number;
}

const MarksList: FC<IMarksListProps> = ({ supplierUuid, supplierRegions, supplierCategories }): JSX.Element => {
  const [markDocInfo, setMarkDocInfo] = useState<DocumentListType | null>(null);
  const [contractorMarkList, setContractorMarkList] = useState<MarkType[]>([]);
  const [tableParams, setTableParams] = useState<DocumentTableParamsType>({
    page: 1,
    order_by: "created_at"
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);

    getMarkInfo(supplierUuid as string, tableParams)
      ?.then((response) => {
        const docInfo: DocumentListType = response?.data;
        const markListDocumentFields: MarkType[] = response?.data?.marks;

        setMarkDocInfo(docInfo);
        createContractorMarkListList(markListDocumentFields);
      })
      ?.catch((error: any): void => console.error("Get marks list error", error))
      ?.finally((): void => setIsLoading(false));
  }, [tableParams]);

  const createContractorMarkListList = (marksList: MarkType[]): void => {
    const markDocument: ProcessedMark[] = marksList?.map((
      {
        uuid,
        author,
        short_name,
        created_at,
        deadline_criteria,
        expectation_criteria,
        communication_criteria,
        material_security_criteria,
      }: MarkType): ProcessedMark => {
      return {
        uuid,
        short_name: `${formatAuthorName(String(author))}/${short_name}`,
        deadline_criteria: deadline_criteria?.criteria_points ?? 0,
        expectation_criteria: expectation_criteria?.criteria_points ?? 0,
        communication_criteria: communication_criteria?.criteria_points ?? 0,
        material_security_criteria: material_security_criteria?.criteria_points ?? 0,
        created_at: dayjs(created_at)?.format("DD.MM.YYYY"),
      };
    });

    setContractorMarkList(markDocument);
  };

  const handleSort = (sortField: string): void => {
    setTableParams({
      ...tableParams,
      order_by: sortField,
    });
  };

  const handlePageChange = (currentPage: number): void => {
    setTableParams({
      ...tableParams,
      page: currentPage,
    });
  };

  const handlePageSizeChange = (pageSize: number): void => {
    setTableParams({
      ...tableParams,
      page: 1,
      page_size: pageSize,
    });
  };

  return (
    <div className="p-4 bg-white rounded-xl">
      <SuppliersTableFilters
        tableParams={tableParams}
        setTableParams={setTableParams}
        isMarkList
        supplierRegions={supplierRegions}
        supplierCategories={supplierCategories}
      />
      <CustomTable
        data={contractorMarkList}
        linkTo={`supplier/${supplierUuid}/marks`}
        isLoading={isLoading}
        subheadKeys={subheadKeys}
        columnWidth={columnWidth}
        ratingKeys={ratingKeys}
        sortableColumns={sortableColumns}
        sortableFunction={handleSort}
      />
      <CustomPagination
        total={markDocInfo?.total_docs ?? 0}
        pageSize={markDocInfo?.page_size ?? 20}
        currentPage={markDocInfo?.page ?? 1}
        handlePageChange={handlePageChange}
        handlePageSizeChange={handlePageSizeChange}
      />
    </div>
  );
};

export default MarksList;
